const PHASE_ONE = [
  'start',
  'first-time',
  'whos-it-for',
  'when-are-you',
  'email',
  'name',
  'register',
  'apply',
];

const PHASE_TWO = [
  'address',
  'paying-rent',
  'income',
  'where-are-you',
  'down-payment',
  'agent',
  'credit',
  'partners', // all
  'employment',
  'assets',
  'submit',
];

export function getProgress (name:string) {
  if (!name) {
    return 0;
  }

  let index = PHASE_ONE.findIndex(screen => screen === name);

  if (index < 0) {
    index = PHASE_TWO.findIndex(screen => name.startsWith(screen));

    if (index < 0) {
      return 0;
    }

    return Math.round(index / PHASE_TWO.length * 100);
  }

  return Math.round(index / (PHASE_ONE.length - 1) * 100);
}
