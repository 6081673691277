<style lang="scss" scoped>
  .header-container {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .logo {
    width: 20rem;
  }

  .mobile-logo {
    width: 16rem;
  }

  .progress-container {
    padding-top: 0 !important;
    padding-bottom: 1.5rem !important;

    .v-progress-linear {
      @media only screen and (min-width: 960px) {
        top: -8px !important;
      }
    }
  }

  .v-main {
    background-color: #fff;

    > div {
      min-height: 60vh;
    }
  }

  .v-footer .logo {
    width: 9rem;
  }

  .v-footer img {
    width: 3.75rem;
  }
</style>

<template>
  <v-app>
    <v-container class="header-container">
      <div v-if="showAuth" class="text-right d-md-none">
        <p class="label">Need help? <a class="text-decoration-none" href="tel:15138248171">Call 1-513-824-8171</a></p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <img class="mobile-logo d-md-none" src="~/assets/images/homebuyer-logo-disclosure.png" alt="Homebuyer.com logo">
        <img class="logo d-none d-md-block" src="~/assets/images/homebuyer-logo-disclosure.png" alt="Homebuyer.com logo">
        <div v-if="showAuth">
          <div class="d-none d-md-inline">
            Need help? <a class="text-decoration-none" href="tel:15138248171">Call 1-513-824-8171</a>
            <span class="mx-1 mx-md-2">|</span>
          </div>
          <a v-if="loggedIn" class="text-decoration-none" @click.prevent="onLogout">Logout</a>
          <a v-else class="text-decoration-none" href="/login">Login</a>
        </div>
      </div>
    </v-container>
    <v-container v-if="showProgress" class="progress-container">
      <v-progress-linear color="#5462D1" :model-value="progress" rounded height="8" />
    </v-container>
    <v-divider v-else class="mb-6" />
    <v-main class="d-flex flex-column">
      <div class="flex-grow-1">
        <slot />
      </div>
      <v-divider />
      <v-footer v-if="showFooter" class="flex-grow-0 pa-0 mb-12">
        <v-container>
          <v-row v-if="rate && showRateDisclosure">
            <v-col>
              <small>
                The {{ formatRate(rate.rate * 100) }}% mortgage rate ({{ formatRate(rate.apr * 100)
                }}% APR) is based on information retrieved on {{ formatLongDate(rate.createdAt) }}.
                This rate requires {{ formatPoints(rate.points * 100) }} discount points at closing,
                which costs {{ formatPoints(rate.points * 100) }}% of the starting principal
                balance. Assuming a loan size of {{ formatDollars(rate.loanAmount) }}, the monthly
                payment for the mortgage with the above terms is
                {{ formatDollars(rate.monthlyPayment) }} for 360 months, plus taxes and insurance
                premiums. Homebuyer.com provides this information for estimation purposes only and
                does not guarantee accuracy. Your mortgage rate, APR, loan size, and fees may vary.
              </small>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small>
                &copy; 2021-{{ year }} All rights reserved. Member FDIC. Equal Housing Lender. Novus
                Home Mortgage, a division of Ixonia Bank, NMLS #423065. The website is not available
                in Connecticut, New York, Washington, Hawaii, and Alaska. Growella is not licensed
                or registered to engage in mortgage loan origination activities for mortgage loans
                on 1-4 family residential properties located in New York. This website is not
                approved by the state of New York.  A self-directed mortgage means the customer
                provides application information and selects loan terms independently. Guidance from
                a loan officer is available for informational purposes only. This process is not
                fully automated and does not increase the likelihood of mortgage approval compared
                to a typical mortgage application. All applications are subject to standard
                underwriting and approval criteria.  This website has no affiliation with the US
                Department of Housing and Urban Development, the US Department of Veterans Affairs,
                the US Department of Agriculture, or any other government agency. US government
                agencies have not reviewed this information, and this site is not connected with any
                government agency.
              </small>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <img
                src="~/assets/images/equal-housing-lender.svg"
                class="mr-5"
                alt="Equal Housing Lender"
              >
              <img class="mr-5" src="~/assets/images/fdic-member.svg" alt="Member FDIC">
              <img class="logo" src="~/assets/images/novus-logo.svg" alt="Novus logo">
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
    <Notice
      v-model="notice"
      :text="message"
    />
  </v-app>
</template>

<script setup>
import { clearToken } from '@/services';
import { getProgress } from '@/utils/progress';
import { useNurlaStore } from '@/store';
import { useRatesStore } from '@/store/rates';

const nurlaStore = useNurlaStore();
const ratesStore = useRatesStore();

const route = useRoute();

const progress = ref(0);
const notice = ref(false);
const message = ref(null);
const year = ref((new Date()).getFullYear());

watch(() => route.name, (name) => {
  progress.value = getProgress(name);
});

watch(() => nurlaStore.flash, (flash) => {
  if (flash.message) {
    notice.value = true;
    message.value = flash.message;
  }
});

watch(notice, (newValue) => {
  if (!newValue) {
    nurlaStore.setFlash({ message: null });
  }
});

const loggedIn = computed(() => {
  return nurlaStore.user.loggedIn;
});

const rate = computed(() => {
  return ratesStore.rates.rate;
});

const showRateDisclosure = computed(() => {
  return ['start'].includes(useRoute().name);
});

const showProgress = computed(() => {
  return !['rates', 'refer', 'login', 'thank-you', 'completed', 'finalize'].includes(useRoute().name);
});

const showAuth = computed(() => {
  return !['rates'].includes(useRoute().name);
});

const showFooter = computed(() => {
  return !['rates'].includes(useRoute().name);
});

function onClose () {
  useGtm().trackEvent({ event: 'Nurla Exit', eventCategory: useRoute().name });
}

async function onLogout () {
  nurlaStore.resetState();
  await clearToken();
  nurlaStore.setFlash({ message: 'You are now logged out.' });
  await navigateTo({ name: 'start' });
}

onMounted(() => {
  progress.value = getProgress(route.name);
  window.addEventListener('beforeunload', onClose);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', onClose);
});

</script>
